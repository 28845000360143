export const environment = {
    "production": true,
    "serviceName": "CoverPortal",
    "env": "production",
    "amplify": {
        "Auth": {
            "mandatorySignIn": true,
            "region": "us-west-2",
            "userPoolId": "us-west-2_GqgskWIVK",
            "identityPoolId": "",
            "userPoolWebClientId": "2nnfv86d5dthheobdsed447ncp",
            "cookieStorage": {
                "domain": ".precision-autonomy.com",
                "secure": true,
                "path": "/",
                "expires": 7,
                "sameSite": "lax"
            },
            "oauth": {
                "domain": "auth.precision-autonomy.com",
                "redirectSignIn": "https://cover.precision-autonomy.com/",
                "redirectSignOut": "https://cover.precision-autonomy.com/",
                "responseType": "code"
            }
        }
    },
    "apiBaseUrls": {
        "cmf": "https://cmf.precision-autonomy.com/api",
        "rmf": "https://rmf.precision-autonomy.com/api",
        "idf": "https://idf.precision-autonomy.com/api",
        "pay": "https://pay.precision-autonomy.com/api",
        "loginPortal": "https://login.precision-autonomy.com"
    }
}